import React, { Fragment } from 'react';
import { Token, compressImageBase64, resizeImageBase64, host, api, pesan, saiki, submitForm } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';
import imgLoading from '../assets/img/loading-upload.gif';

class MasterAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JmlData: 1,
            Order: "Nama",
            By: "ASC",
            Page: 1,
            q: "",
            lblSubmit: "fa fa-save",
            btnSubmit: false,
            thead: [],
            tbody: [],
            ID: 0,
            Detail: {},
            ChangeImg: "tidak",
            Sosmed: [],
            Foto: "",
        };
    }

    componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("/master", { act: "data anggota", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "200px" },
                { text: "Foto", field: "Foto", sort: "Foto", type: "img" },
                { text: "Nama", field: "Nama", sort: "Nama" },
                { text: "Telp", field: "Telp", sort: "Telp" },
                { text: "Email", field: "Email", sort: "Email" },
                { text: "Status", field: "SSTS", sort: "Status" }
            ];
            this.setState({ tbody: sql.data, thead: thead })
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    handleDetail(data) {
        if (data != "baru") {
            this.setState({ Detail: data, Sosmed: JSON.parse(data.Sosmed), Foto: data.Foto == "" ? imgUpload : host.replace("cp", '') + data.Foto, ChangeImg: "tidak" });
        } else {
            this.setState({ Detail: {}, Sosmed: [], Foto: imgUpload, ChangeImg: "tidak" });
        }
        let modal = new window.bootstrap.Modal(document.getElementById('modalForm'), {});
        modal.show("#modalForm");
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    async handleProsesDelete(e) {
        let sql = await api("master", { act: "hapus anggota", ID: this.state.ID });
        if (sql.status == "sukses") {
            this.handleMain();
            pesan("Sukses", sql.pesan, "primary");
            document.getElementById('btnTutupModalDelete').click();
        } else {
            this.setState({ lblSubmit: "fas fa-trash", btnSubmit: "false" });
            pesan("Gagal", sql.pesan, "danger");
        }
    }

    handlePilihBG(e, compress = true) {
        this.setState({ Foto: imgLoading });
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 && compress == true ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 && compress == true ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        this.setState({ Foto: newRes, ChangeImg: "ya" });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header">
                    <button className='btn btn-default' onClick={() => this.handleDetail("baru")}>Tambah Anggota</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Master Anggota</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-edit", text: "Edit", opsi: (e) => this.handleDetail(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Form Input edit */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { crud: 'master', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah anggota" : "edit anggota"} />
                            <input type='hidden' name="ID" value={this.state.Detail.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah" : "Edit"} Anggota</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Nama Depan</label>
                                                <input type="text" className="form-control" name='NamaDepan' id="edtNamaDepan" value={this.state.Detail.NamaDepan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NamaDepan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan isi nama Depan</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Nama Belakang</label>
                                                <input type="text" className="form-control" name='NamaBelakang' id="edtNamaBelakang" value={this.state.Detail.NamaBelakang || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NamaBelakang = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className="invalid-feedback">Silahkan isi nama Belakang</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="email" className="form-control" name='Email' id="edtEmail" value={this.state.Detail.Email || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Email = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan isi Email</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Telp</label>
                                                <input type="number" className="form-control" name='Telp' id="edtTelp" value={this.state.Detail.Telp || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Telp = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan isi Telp</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Pendidikan</label>
                                                <input type="text" className="form-control" name='Pendidikan' id="edtPendidikan" value={this.state.Detail.Pendidikan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Pendidikan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan isi Pendidikan</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Posisi</label>
                                                <input type="text" className="form-control" name='Posisi' id="edtPosisi" value={this.state.Detail.Posisi || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Posisi = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan isi Posisi</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Alamat</label>
                                        <textarea className="form-control" name='Alamat' id="edtAlamat" value={this.state.Detail.Alamat || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Alamat = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className="invalid-feedback">Silahkan isi Alamat</div>
                                    </div>

                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className="invalid-feedback">Silahkan isi Keterangan</div>
                                    </div>
                                    <p></p>
                                    <button className='btn btn-sm btn-default' type='button' onClick={() => {
                                        let data = this.state.Sosmed;
                                        data.push({ sosmed: "facebook", link: "https://facebook.com/", icon: "fab fa-facebook" })
                                        this.setState({ Sosmed: data });
                                    }}>Tambah Sosmed</button>
                                    <p></p>
                                    <input type="hidden" name="Sosmed" value={JSON.stringify(this.state.Sosmed)} />
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Soses</th>
                                                    <th>Link</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Sosmed.map((tr, i) => {
                                                        return (<tr key={i}>
                                                            <td>
                                                                <select className='form-select form-select-sm' value={tr.sosmed} onChange={(e) => {
                                                                    let data = this.state.Sosmed;
                                                                    let val = e.target.value;
                                                                    data[i].sosmed = val;
                                                                    let icon = "fab fa-facebook";
                                                                    if (val == "facebook") {
                                                                        icon = "fab fa-facebook";
                                                                    } else if (val == "instagram") {
                                                                        icon = "fab fa-instagram-square";
                                                                    } else if (val == "twitter") {
                                                                        icon = "fab fa-twitter";
                                                                    } else if (val == "tiktok") {
                                                                        icon = "fab fa-tiktok";
                                                                    } else if (val == "linkin") {
                                                                        icon = "fab fa-linkedin-in";
                                                                    }
                                                                    data[i].icon = icon;
                                                                    this.setState({ Sosmed: data });
                                                                }}>
                                                                    <option value="facebook">Facebook</option>
                                                                    <option value="instagram">Instagram</option>
                                                                    <option value="twitter">Twitter</option>
                                                                    <option value="linkin">Linkin</option>
                                                                    <option value="tiktok">Tiktok</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input type='link' className='form-control form-control-sm' value={tr.link} onChange={(e) => {
                                                                    let data = this.state.Sosmed;
                                                                    data[i].link = e.target.value;
                                                                    this.setState({ Sosmed: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <button className='btn btn-sm' type='button' onClick={() => {
                                                                    let data = this.state.Sosmed;
                                                                    let temp = [];
                                                                    for (let ii in data) if (i != ii) temp.push(data[ii]);
                                                                    this.setState({ Sosmed: temp });
                                                                }}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='form-group'>
                                        <label>Foto Profile</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input type='file' name='Files' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                            <img src={this.state.Foto} htmlFor="Foto" style={{ width: "100px", height: "100px" }} />
                                        </label>
                                    </div>
                                    <p></p>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusText" name="Status" checked={this.state.Detail.Status} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Status = e.target.checked == true ? 1 : 0;
                                            this.setState({ Detail: data });
                                        }} />
                                        <label className="form-check-label" htmlFor="chkStatusText">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalForm'>Batal</button>
                                    <button type="submit" className="btn btn-default" disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* modal Hapus */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Hapus Anggota</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h5>Apakah anda yakin akan menghaus anggota!</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal" id='btnTutupModalDelete'>Batal</button>
                                <button type="button" className="btn btn-danger" onClick={(e) => this.handleProsesDelete(e)} disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MasterAdmin;
