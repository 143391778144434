import React from "react";
import { numberFormat, tanggalIndo, host } from "../Modul";

class RendTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thead: [],
            tbody: [],
            opt: [],
            tableTheme: ""
        };
    }

    componentDidMount() {
        this.handleRender();
        let colorTheme = localStorage.getItem("ColorTheme") || "light";
        if (colorTheme == "dark") this.setState({ tableTheme: "table-dark" })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.thead !== this.props.thead || prevProps.tbody !== this.props.tbody) this.handleRender();
    }

    handleRender() {
        let thead = this.props.thead;
        let tbody = this.props.tbody;
        let opt = this.props.opt;
        this.setState({ thead, tbody, opt });
    }

    handleActive(e) {
        var activeElements = document.querySelectorAll('.table-active');
        activeElements.forEach(function (element) {
            element.classList.remove('table-active');
        });
        if (e.target.tagName == "TD") {
            e.target.parentNode.classList.add("table-active");
        }
    }

    render() {
        const { thead, tbody, opt, tableTheme } = this.state;

        return (
            <div className="table-responsive">
                <table className={`table ${tableTheme} table-striped`}>
                    <thead>
                        <tr>
                            {thead.map((th, i) => (
                                <th key={i} onClick={() => th.sort && opt.sort && opt.sort(th.field)} width={th.width || "auto"}>
                                    {th.text}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tbody.length > 0 ? (
                            tbody.map((row, rowIndex) => (
                                <tr key={rowIndex} onClick={(e) => this.handleActive(e)}>
                                    {thead.map((th, colIndex) => {
                                        if (th.type == "opsi") {
                                            return (
                                                <td key={colIndex}>
                                                    {opt.opsi.map((btn, ii) => (
                                                        <button className="btn" onClick={() => btn.opsi(row)} key={`opsi${ii}`} tooltip={btn.text}>
                                                            <i className={btn.icon}></i>
                                                        </button>
                                                    ))}
                                                </td>
                                            )
                                        } else if (th.type == "html") {
                                            return (<td key={colIndex} dangerouslySetInnerHTML={{ __html: row[th.field] }}></td>)
                                        } else if (th.type == "img") {
                                            return (<td key={colIndex}>
                                                <img src={host.replace("/cp/", "") + row[th.field]} width="100px" height="100px" style={{ borderRadius: "50%" }} />
                                            </td>)
                                        } else if (th.type == "number") {
                                            return (<td key={colIndex}>{numberFormat(row[th.field])}</td>)
                                        } else if (th.type == "date") {
                                            return (<td key={colIndex}>{tanggalIndo(row[th.field])}</td>)
                                        } else if (th.type == "boolean") {
                                            if (row[th.field] == "0") {
                                                return (<td key={colIndex}> <span className="badge bg-danger">Tidak Aktif</span></td>)
                                            } else {
                                                return (<td key={colIndex}> <span className="badge bg-primary">Aktif</span></td>)
                                            }
                                        } else if (th.type == "loading") {
                                            return (<td className="placeholder-glow"> <span className="placeholder col-12"></span></td>)
                                        } else {
                                            return (<td key={colIndex}>{row[th.field]}</td>)
                                        }
                                    })}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={thead.length}>Tidak Ada data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default RendTable;