import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Token, compressImageBase64, resizeImageBase64, host, api, pesan, isJson, submitForm } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';
import imgLoading from '../assets/img/loading-upload.gif';

class MasterProduk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JmlData: 1,
            Order: "Nama",
            By: "ASC",
            Page: 1,
            q: "",
            lblSubmit: "fa fa-save",
            btnSubmit: false,
            thead: [],
            tbody: [],
            Detail: { Keterangan: "" },
            Status: true,
            Foto: imgUpload,
            ImageSrc: imgUpload
        };
    }

    componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("/master", { act: "data jenis produk", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "200px" },
                { text: "Nama", field: "Nama", sort: "Nama" },
                { text: "Keterangan", field: "Keterangan", sort: "Keterangan", type: "html" },
                { text: "Status", field: "Status", sort: "Status" }
            ];
            this.setState({
                tbody: sql.data,
                thead: thead,
                JmlData: sql.j,
                Detail: { Keterangan: "" },
                Foto: imgUpload,
                Status: true,
                lblSubmit: "fas fa-save",
                btnSubmit: false
            });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    async handleDetail(data) {
        if (data != "baru") {
            this.setState({
                Detail: data,
                ChangeImg: "tidak",
                ImageSrc: host.replace("/cp", "") + data.Icon
            });
        } else {
            this.setState({
                Detail: { Keterangan: "" },
                ChangeImg: "tidak",
                ImageSrc: imgUpload
            });
        }
        let modal = new window.bootstrap.Modal(document.getElementById('modalForm'), {});
        modal.show("#modalForm");
    }

    async handleSubmit(e, btn) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.checkValidity()) {
            this.setState({ lblSubmit: "spinner-border spinner-border-sm text-light", btnSubmit: true });
            let data = new FormData(e.target);
            data.append("Token", Token);
            fetch(host + "master", {
                method: 'POST',
                body: data,
            }).then(response => response.text()).then(hasil => {
                if (isJson(hasil)) {
                    hasil = JSON.parse(hasil);
                    if (hasil.status === "sukses") {
                        this.handleMain();
                        document.getElementById(btn).click();
                        pesan("Proses Success", hasil.pesan, "primary");
                    } else {
                        this.setState({ lblSubmit: "fas fa-save", btnSubmit: false });
                        pesan("Proses Gagal", hasil.pesan, "danger");
                    }
                } else {
                    this.setState({ lblSubmit: "fas fa-save", btnSubmit: false });
                    console.log(hasil);
                }
            }).catch((error) => {
                this.setState({ lblSubmit: "fas fa-save", btnSubmit: false });
                console.log("Error: " + error);
            });
        } else {
            let forms = document.getElementsByClassName('needs-validation');
            let ululu = Array.prototype.filter.call(forms, function (form) {
                form.classList.add('was-validated');
            });
        }
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    async handleProsesDelete(e) {
        let sql = await api("master", { act: "hapus jenis produk", ID: this.state.ID });
        if (sql.status == "sukses") {
            this.handleMain();
            pesan("Sukses", sql.pesan, "primary");
            document.getElementById('btnTutupModalDelete').click();
        } else {
            this.setState({ lblSubmit: "fas fa-trash", btnSubmit: "false" });
            pesan("Gagal", sql.pesan, "danger");
        }
    }

    handlePilihBG(e, compress = false) {
        this.setState({ ImageSrc: imgLoading });
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 && compress == true ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 && compress == true ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        this.setState({ ImageSrc: newRes });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header">
                    <button className='btn btn-default' onClick={() => this.handleDetail("baru")}>Tambah Produk</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Master Jenis Produk</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Master' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-edit", text: "Edit", opsi: (e) => this.handleDetail(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Form Input edit */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { crud: 'master', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah jenis produk" : "edit jenis produk"} />
                            <input type='hidden' name="ID" value={this.state.Detail.ID} />
                            <input type='hidden' name="ChangeImg" value={this.state.ChangeImg} />
                            <input type='hidden' name="Keterangan" value={this.state.Detail.Keterangan} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah" : "Edit"} Jenis Produk</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Nama' name='Nama' value={this.state.Detail.Nama || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Nama = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.Detail.Keterangan || ""}
                                            onChange={(e) => {
                                                let val = e;
                                                let data = this.state.Detail;
                                                data.Keterangan = val;
                                                this.setState({ Detail: data });
                                            }}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                                    ['clean'],
                                                ],
                                            }}
                                        />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Background Images</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input name='Files' type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                            <img src={this.state.ImageSrc} htmlFor="fileFotoPanjang" style={{ width: "50%" }} />
                                        </label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalForm'>Batal</button>
                                    <button type="submit" className="btn btn-default" disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* modal Hapus */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Hapus Jenis Produk</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h5>Apakah anda yakin akan menghapus produk!</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal" id='btnTutupModalDelete'>Batal</button>
                                <button type="button" className="btn btn-danger" onClick={(e) => this.handleProsesDelete(e)} disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MasterProduk;
