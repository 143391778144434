import React, { Fragment, Component } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { compressImageBase64, resizeImageBase64, sizeBase64, extensionBase64, api, openFile, detailGambar, host } from "../Modul";
import imgLoading from '../assets/img/loading-upload.gif';
import imgUpload from '../assets/img/img-upload.png';
import ImageEditor from './ImageEditor';

function RendForms(type) {
    class FormArtikel extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                Detail: {},
                Kategori: [],
                Banner: imgUpload
            };
        }

        async componentDidMount() {
            let sql = await api("/artikel", { act: "data kategori" });
            if (sql.status == "sukses") this.setState({ Kategori: sql.data });
            this.handleRender();
            let colorTheme = localStorage.getItem("ColorTheme") || "light";
            if (colorTheme == "dark") this.setState({ tableTheme: "table-dark" })
        }

        componentDidUpdate(prevProps) {
            if (prevProps.Detail !== this.props.Detail) this.handleRender();
        }

        handleRender() {
            let Detail = this.props.Detail;
            this.setState({ Detail });
            if (Detail.Banner != "") this.setState({ Banner: Detail.Banner });
        }

        render() {
            const { Detail, Kategori } = this.state;
            return (
                <Fragment>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className='form-group'>
                                <label>Judul</label>
                                <input type="text" className='form-control' placeholder='Silahkan isi Konten' name='Judul' value={Detail.Judul} onChange={(e) => {
                                    let detail = Detail;
                                    detail.Judul = e.target.value;
                                    this.setState({ Detail: detail });
                                }} required />
                                <div className='invalid-feedback'>Data Harus di isi</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className='form-group'>
                                <label>Kategori</label>
                                <select name="KategoriID" className="form-select" value={Detail.KategoriID} onChange={(e) => {
                                    let data = Detail;
                                    data.KategoriID = e.target.value;
                                    this.setState({ Detail: data });
                                }} required>
                                    {
                                        Kategori.map((opt, i) => {
                                            return (<option value={opt.ID} key={opt.ID}>{opt.Nama}</option>)
                                        })
                                    }
                                </select>
                                <div className='invalid-feedback'>Data Harus di isi</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className='form-group'>
                                <label>Tanggal</label>
                                <input type="date" className='form-control' placeholder='Silahkan isi Konten' name='Tanggal' value={Detail.Tanggal} onChange={(e) => {
                                    let detail = Detail;
                                    detail.Tanggal = e.target.value;
                                    this.setState({ Detail: detail });
                                }} required />
                                <div className='invalid-feedback'>Data Harus di isi</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className='form-group'>
                                <label>Status</label>
                                <select name="Status" className="form-select" value={Detail.Status} onChange={(e) => {
                                    let data = Detail;
                                    data.Status = e.target.value;
                                    this.setState({ Detail: data });
                                }} required>
                                    <option value="Draft">Draft</option>
                                    <option value="Publish">Publish</option>
                                    <option value="Hapus">Hapus</option>
                                </select>
                                <div className='invalid-feedback'>Silahkan Pilih Status</div>
                            </div>
                        </div>
                    </div>

                    <ImageEditor label="Banner Artikel" Name="Banner" Gambar={this.state.Banner} />
                    <div className='form-group'>
                        <label>Konten</label>
                        <ReactQuill
                            theme="snow"
                            value={Detail.Artikel}
                            onChange={(e) => {
                                let val = e;
                                let data = Detail;
                                data.Artikel = val;
                                this.setState({ Detail: data });
                            }}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, false] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                    ['clean'],
                                ],
                            }}
                        />
                        <div className='invalid-feedback'>Data Harus di isi</div>
                    </div>
                    <input type="hidden" name="Artikel" value={Detail.Artikel} />
                </Fragment>
            );
        }
    }

    class FormBaru extends Component {
        constructor(props) {
            super(props);
            this.state = {
                value: '<p>Tulis berita Anda di sini.</p>',
            };
        }

        handleChange = (content) => {
            this.setState({ value: content });
        };

        render() {
            return (
                <div>
                    <ReactQuill
                        theme="snow"
                        value={this.state.value}
                        onChange={this.handleChange}
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, false] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                ['link', 'image'],
                                ['clean'],
                            ],
                        }}
                    />
                </div>
            );
        }
    }

    class FormImages extends Component {
        constructor(props) {
            super(props);
            this.state = {
                Gambar: "",
                Detail: {},
                newImg: "",
                tableTheme: "",
                Kualitas: 100,
                Resolusi: 0
            };
        }

        componentDidMount() {
            let colorTheme = localStorage.getItem("ColorTheme") || "light";
            if (colorTheme == "dark") this.setState({ tableTheme: "table-dark" })
            this.handleRender();
        }

        componentDidUpdate(prevProps) {
            if (prevProps.Detail !== this.props.Detail) this.handleRender();
        }

        handleRender() {
            let Gambar = this.props.Gambar != "" ? this.props.Gambar : imgUpload;
            let Detail = detailGambar(Gambar);
            this.setState({ Gambar, Detail, newImg: Gambar });
        }

        async handleResolusi(e) {
            let resolusi = e.target.value;
            let Gambar = this.state.Gambar;
            if (resolusi > 0) {
                let ext = extensionBase64(Gambar);
                let img = new Image();
                img.src = Gambar;
                img.onload = async () => {
                    let width = img.width;
                    let height = img.height;
                    let newRes = await resizeImageBase64(Gambar, height / resolusi, width / resolusi, ext);
                    let size = sizeBase64(newRes).sizeKB;
                    let gambar = { size: size, width: Math.round(width / resolusi), height: Math.round(height / resolusi) };
                    this.setState({ newImg: newRes, Detail: gambar, Resolusi: resolusi });
                }
            } else {
                let Detail = detailGambar(Gambar);
                this.setState({ Detail: Detail, newImg: Gambar });
            }
        }

        async handleKualitas(e) {
            let Gambar = this.state.Gambar;
            let Detail = this.state.Detail;
            let Kualitas = e.target.value;
            let baru = await compressImageBase64(Gambar, Detail.ext, 1 / Kualitas);
            let newDetail = detailGambar(baru);
            this.setState({ Kualitas: Kualitas, newImg: baru, Detail: newDetail });
        }

        async handlePilihBG(e) {
            let File = await openFile(e);
            let Gambar = File[0].img;
            let Detail = detailGambar(Gambar);
            this.setState({ Gambar: Gambar, newImg: Gambar, Detail: Detail });
        }

        render() {
            return (
                <div className="row">
                    <div className="col-md-9">
                        <div className="form-group">
                            <label>Gambar</label>
                            <label className='d-flex justify-content-center align-items-center'>
                                <input id="BackgroundImage" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e, true)} />
                                <img src={this.state.newImg} htmlFor="BackgroundImage" style={{ width: "100%" }} />
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h5>Detail Gambar</h5>
                        <table className={`table table-resiped ${this.state.tableTheme}`}>
                            <tbody>
                                <tr>
                                    <td>Resolusi</td>
                                    <td>:</td>
                                    <td>{this.state.Detail.width} X {this.state.Detail.height}</td>
                                </tr>
                                <tr>
                                    <td>Kualitas</td>
                                    <td>:</td>
                                    <td>{this.state.Kualitas} %</td>
                                </tr>
                                <tr>
                                    <td>Size</td>
                                    <td>:</td>
                                    <td>{this.state.Detail.size} KB</td>
                                </tr>
                            </tbody>
                        </table>
                        <label htmlFor="rangeResolusi" className="form-label">Resolusi</label>
                        <input type="range" className="form-range" value={this.state.Resolusi} min="0" max="10" onChange={(e) => this.handleResolusi(e)} id="rangeResolusi" />
                        <br></br>
                        <label htmlFor="rangeKualitas" className="form-label">Kualitas</label>
                        <input type="range" className="form-range" value={this.state.Kualitas} min="0" max="10" onChange={(e) => this.handleKualitas(e)} id="rangeKualitas" />
                    </div>
                </div>

            );
        }
    }

    if (type.type == "Artikel") {
        return <FormArtikel Detail={type.Detail} />
    } else if (type.type == "baru") {
        return <FormBaru Detail={type.Detail} />
    } else if (type.type == "EditorGambar") {
        return <FormImages Gambar={type.Detail} />
    }
}

export function TextForm() {
    class TextForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                Value: '',
                Name: "input",
                Required: false,
                Placeholder: "",
                Class: "form-control",
                Label: ""
            };
        }

        componentDidMount() {
            this.handleChange();
        }

        componentDidUpdate(prevProps) {
            if (prevProps.Value !== this.state.Value) this.handleChange();
        }

        handleChange() {
            this.setState({
                Value: this.props.Value,
                Name: this.props.Name,
                Required: this.props.Required,
                Placeholder: this.props.Placeholder,
                Class: this.props.Class,
                Label: this.props.label
            });
            console.log(this.state);
        }

        render() {
            let { Value, Name, Required, Placeholder, Class, Label } = this.state;
            return (
                <div className="form-group">
                    <label>{Label}</label>
                    <input type="text" className={Class} name={Name} placeholder={Placeholder} value={Value} required={Required == false ? false : true} onChange={(e) => this.setState({ Value: e.target.value })} />
                    <div className="invalid-feedback">{Required}</div>
                </div>
            );
        }
    }

    return <TextForm />
}

export default RendForms;