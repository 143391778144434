import React from "react";
import { compressImageBase64, resizeImageBase64, sizeBase64, extensionBase64, openFile, detailGambar } from "../Modul";
import imgLoading from '../assets/img/loading-upload.gif';
import imgUpload from '../assets/img/img-upload.png';


class RendTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Gambar: "",
            Detail: {},
            newImg: "",
            tableTheme: "",
            Kualitas: 100,
            Resolusi: 0,
            Label: "Gambar",
            Name: "Gambar"
        };
    }

    componentDidMount() {
        let colorTheme = localStorage.getItem("ColorTheme") || "light";
        if (colorTheme == "dark") this.setState({ tableTheme: "table-dark" })
        this.handleRender();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Label !== this.props.Label || prevProps.Gambar !== this.props.Gambar) this.handleRender();
    }

    handleRender() {
        let Gambar = this.props.Gambar || imgUpload;
        let Label = this.props.Label || this.state.Label;
        let Name = this.props.Name || this.state.Name;
        let img = new Image();
        img.src = Gambar;
        img.onload = async () => {
            let size = sizeBase64(Gambar).sizeKB;
            let ext = extensionBase64(Gambar);
            let width = img.width;
            let height = img.height;
            let Detail = { size: size, width: width, height: height, ext: ext };
            this.setState({ Gambar, Detail, newImg: Gambar, Label: Label, Name: Name });
        };
    }

    async handleResolusi(e) {
        let resolusi = e.target.value;
        let Gambar = this.state.Gambar;
        let Detail = this.state.Detail;
        if (resolusi > 0) {
            let ext = extensionBase64(Gambar);
            let img = new Image();
            img.src = Gambar;
            img.onload = async () => {
                let width = Math.round(img.width / resolusi);
                let height = Math.round(img.height / resolusi);
                let newRes = await resizeImageBase64(Gambar, height, width, ext);
                let size = sizeBase64(newRes).sizeKB;
                Detail.size = size;
                Detail.width = width;
                Detail.height = height;
                this.setState({ newImg: newRes, Detail: Detail, Resolusi: resolusi });
            }
        } else {
            let Detail = detailGambar(Gambar);
            this.setState({ Detail: Detail, newImg: Gambar });
        }
    }

    async handleKualitas(e) {
        let Gambar = this.state.Gambar;
        let Detail = this.state.Detail;
        let Kualitas = e.target.value;
        let baru = await compressImageBase64(Gambar, Detail.ext, 1 / Kualitas);
        let newDetail = detailGambar(baru);
        this.setState({ Kualitas: Kualitas, newImg: baru, Detail: newDetail });
    }

    async handlePilihBG(e) {
        let Files = await openFile(e);
        let file = Files[0];
        let Detail = { width: file.width, height: file.height, size: file.size, ext: file.ext };
        this.setState({ Gambar: file.img, newImg: file.img, Detail: Detail });
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-9">
                    <div className="form-group">
                        <label>{this.state.Label}</label>
                        <label className='d-flex justify-content-center align-items-center'>
                            <input id="BackgroundImage" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e, true)} />
                            <img src={this.state.newImg} htmlFor="BackgroundImage" style={{ width: "50%" }} />
                        </label>
                    </div>
                </div>
                <div className="col-md-3">
                    <h5>Detail Gambar</h5>
                    <table className={`table table-resiped ${this.state.tableTheme}`}>
                        <tbody>
                            <tr>
                                <td>Resolusi</td>
                                <td>:</td>
                                <td>{this.state.Detail.width} X {this.state.Detail.height}</td>
                            </tr>
                            <tr>
                                <td>Kualitas</td>
                                <td>:</td>
                                <td>{this.state.Kualitas} %</td>
                            </tr>
                            <tr>
                                <td>Size</td>
                                <td>:</td>
                                <td>{this.state.Detail.size} KB</td>
                            </tr>
                            <tr>
                                <td>Tipe</td>
                                <td>:</td>
                                <td>{this.state.Detail.ext} </td>
                            </tr>
                        </tbody>
                    </table>
                    <label htmlFor="rangeResolusi" className="form-label">Resolusi</label>
                    <input type="range" className="form-range" value={this.state.Resolusi} min="1" max="10" onChange={(e) => this.handleResolusi(e)} id="rangeResolusi" />
                    <br></br>
                    <label htmlFor="rangeKualitas" className="form-label">Kualitas</label>
                    <input type="range" className="form-range" value={this.state.Kualitas} min="1" max="10" onChange={(e) => this.handleKualitas(e)} id="rangeKualitas" />
                </div>
                <input type="hidden" name={this.state.Name} value={this.state.newImg} />
            </div>
        );
    }
}

export default RendTable;