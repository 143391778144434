import React, { Fragment } from 'react';
import { Token, compressImageBase64, resizeImageBase64, host, api, pesan, isJson } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';
import imgLoading from '../assets/img/loading-upload.gif';

class MasterAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JmlData: 1,
            Order: "Nama",
            By: "ASC",
            Page: 1,
            q: "",
            lblSubmit: "fa fa-save",
            btnSubmit: false,
            thead: [],
            tbody: [],
            HakAkses: [],
            Menu: [],
            ID: 0,
            Nama: "",
            Email: "",
            Telp: "",
            Pwd: "",
            Foto: "",
            Status: true,
            ChangeImg: "tidak"
        };
    }

    componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("/master", { act: "data admin", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "200px" },
                { text: "Nama", field: "Nama", sort: "Nama" },
                { text: "Telp", field: "Telp", sort: "Telp" },
                { text: "Email", field: "Email", sort: "Email" },
                { text: "Status", field: "Status", sort: "Status" }
            ];
            this.setState({
                tbody: sql.data,
                thead: thead,
                JmlData: sql.j,
                Nama: "",
                Email: "",
                Telp: "",
                Status: true,
                lblSubmit: "fas fa-save",
                btnSubmit: false
            });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    handleDetail(data) {
        if (data != "baru") {
            this.setState({
                ID: data.ID,
                Nama: data.Nama,
                Email: data.Email,
                Telp: data.Telp,
                Foto: data.Foto == "" ? imgUpload : host.replace("cp", '') + data.Foto,
                Pwd: "",
                ChangeImg: "tidak",
                Status: data.Status
            });
        } else {
            this.setState({
                ID: "",
                Nama: "",
                Email: "",
                Telp: "",
                Foto: imgUpload,
                Pwd: "",
                ChangeImg: "tidak",
                Status: true
            });
        }
        let modal = new window.bootstrap.Modal(document.getElementById('modalForm'), {});
        modal.show("#modalForm");
    }

    async handleSubmit(e, btn) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.checkValidity()) {
            this.setState({ lblSubmit: "spinner-border spinner-border-sm text-light", btnSubmit: true });
            let data = new FormData(e.target);
            data.append("Token", Token);
            fetch(host + "master", {
                method: 'POST',
                body: data,
            }).then(response => response.text()).then(hasil => {
                if (isJson(hasil)) {
                    hasil = JSON.parse(hasil);
                    if (hasil.status === "sukses") {
                        this.handleMain();
                        document.getElementById(btn).click();
                        pesan("Proses Success", hasil.pesan, "primary");
                    } else {
                        this.setState({ lblSubmit: "fas fa-save", btnSubmit: false });
                        pesan("Proses Gagal", hasil.pesan, "danger");
                    }
                } else {
                    this.setState({ lblSubmit: "fas fa-save", btnSubmit: false });
                    console.log(hasil);
                }
            }).catch((error) => {
                this.setState({ lblSubmit: "fas fa-save", btnSubmit: false });
                console.log("Error: " + error);
            });
        } else {
            let forms = document.getElementsByClassName('needs-validation');
            let ululu = Array.prototype.filter.call(forms, function (form) {
                form.classList.add('was-validated');
            });
        }
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    async handleProsesDelete(e) {
        let sql = await api("master", { act: "hapus admin", ID: this.state.ID });
        if (sql.status == "sukses") {
            this.handleMain();
            pesan("Sukses", sql.pesan, "primary");
            document.getElementById('btnTutupModalDelete').click();
        } else {
            this.setState({ lblSubmit: "fas fa-trash", btnSubmit: "false" });
            pesan("Gagal", sql.pesan, "danger");
        }
    }

    async handleHakAkses(data) {
        let sql = await api("master", { act: "data hak akses", ID: data.UserID });
        if (sql.status == "sukses") {
            this.setState({ HakAkses: JSON.parse(sql.akses), Menu: JSON.parse(sql.menu), ID: data.UserID });
            let modal = new window.bootstrap.Modal(document.getElementById('modalHakAkses'), {});
            modal.show("#modalHakAkses");
        }
    }

    handleChangeHakAkses(i, e) {
        let HakAkses = this.state.HakAkses;
        let Menu = this.state.Menu;
        if (e.target.checked == true) {
            HakAkses.push(Menu[i]);
            this.setState({ HakAkses: HakAkses });
        } else {
            let tempHakAkses = [];
            for (let dd of HakAkses) {
                if (Menu[i].App != dd.App) tempHakAkses.push(dd);
            }
            this.setState({ HakAkses: tempHakAkses });
        }
    }

    async handleProsesHakAkses(e) {
        this.setState({ btnSave: true, lblSubmit: "spinner-border spinner-border-sm text-light" });
        let sql = await api("master", { act: "edit hak akses", UserID: this.state.ID, Menu: JSON.stringify(this.state.HakAkses) }, true);
        if (sql.status == "sukses") {
            this.setState({ btnSave: false, lblSubmit: "fas fa-save" });
            pesan("Berhasil", sql.pesan, "primary");
            document.getElementById('btnTutupModalHakAkses').click();
        } else {
            this.setState({ btnSave: false, lblSubmit: "fas fa-save" });
            pesan("Gagal", sql.pesan, "danger");
        }
    }

    handlePilihBG(e, compress = true) {
        this.setState({ Foto: imgLoading });
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            let file = files[i];
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let fileSizeKB = file.size / 1024;
            reader.onload = async () => {
                try {
                    let baru = fileSizeKB > 600 && compress == true ? await compressImageBase64(reader.result, fileExtension, 0.8) : reader.result;
                    let img = new Image();
                    img.src = baru;
                    img.onload = async () => {
                        let width = img.width;
                        let height = img.height;
                        let newRes = fileSizeKB > 600 && compress == true ? await resizeImageBase64(baru, height / 5, width / 5, fileExtension) : baru;
                        this.setState({ Foto: newRes, ChangeImg: "ya" });
                    };
                } catch (error) {
                    console.error('Error compressing image:', error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header">
                    <button className='btn btn-default' onClick={() => this.handleDetail("baru")}>Tambah Admin</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Master Admin</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-edit", text: "Edit", opsi: (e) => this.handleDetail(e) },
                                { icon: "fas fa-shield-alt", text: "Hak Akses", opsi: (e) => this.handleHakAkses(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Form Input edit */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => this.handleSubmit(e, "btnTutupModalForm")} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value={this.state.ID == "" ? "tambah admin" : "edit admin"} />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <input type='hidden' name="ChangeImg" value={this.state.ChangeImg} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah" : "Edit"} Admin</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Nama' name='Nama' value={this.state.Nama} onChange={(e) => this.setState({ Nama: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Email</label>
                                        <input type="text" className='form-control' placeholder='Silahkan isi Email' name='Email' value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Telp</label>
                                        <input type="number" className='form-control' placeholder='Silahkan isi Telp' name='Telp' value={this.state.Telp} onChange={(e) => this.setState({ Telp: e.target.value })} required />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    {
                                        this.state.ID == "" ?
                                            <div className='form-group'>
                                                <label>Password</label>
                                                <input type="password" className='form-control' placeholder='Masukan Kata sandi' name='Pwd' value={this.state.Pwd} onChange={(e) => this.setState({ Pwd: e.target.value })} required />
                                                <div className='invalid-feedback'>Data Harus di isi</div>
                                            </div> : <div></div>
                                    }
                                    <div className='form-group'>
                                        <label>Foto Profile</label>
                                        <input type='hidden' name='Foto' value={this.state.Foto} />
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input id="Foto" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihBG(e)} />
                                            <img src={this.state.Foto} htmlFor="Foto" style={{ width: "100px", height: "100px" }} />
                                        </label>
                                    </div>
                                    <p></p>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="chkStatusText" name="Status" checked={this.state.Status} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} />
                                        <label className="form-check-label" htmlFor="chkStatusText">Aktif</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalForm'>Batal</button>
                                    <button type="submit" className="btn btn-default" disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* modal Hapus */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Hapus Admin</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h5>Apakah anda yakin akan menghaus admin!</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal" id='btnTutupModalDelete'>Batal</button>
                                <button type="button" className="btn btn-danger" onClick={(e) => this.handleProsesDelete(e)} disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* modal Hak Akses */}
                <div className="modal fade" id="modalHakAkses" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Hak Akses Admin</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="table-responsive">
                                    <table className='table table-dark table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Menu</th>
                                                <th>Akses</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.Menu.map((tr, i) => {
                                                    let Status = false;
                                                    for (let dd of this.state.HakAkses) if (tr.App == dd.App) Status = true;
                                                    return (
                                                        <tr key={i}>
                                                            <td>{tr.Nama}</td>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" role="switch" name="Status" checked={Status} onChange={(e) => this.handleChangeHakAkses(i, e)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalHakAkses'>Batal</button>
                                <button type="button" className="btn btn-default" onClick={(e) => this.handleProsesHakAkses(e)} disabled={this.state.btnSave}> <i className={this.state.lblSubmit}></i> Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MasterAdmin;
