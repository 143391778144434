import React from "react";

class Paggination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Li: []
        };
    }

    componentDidMount() {
        this.generateLi();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Page !== this.props.Page || prevProps.Jml !== this.props.Jml) {
            this.generateLi();
        }
    }

    generateLi() {
        let Li = [];
        let Page = this.props.Page;
        for (let i = 1; i <= this.props.Jml; i++) {
            Li.push({
                Numb: i,
                Active: i == Page
            });
        }

        this.setState({ Li: Li });
    }

    handleNect(e) {

    }

    render() {
        if (this.state.Li.length > 0) {
            return (
                <nav aria-label="Paggination">
                    <ul className="pagination">
                        <li className="page-item disabled">
                            <span className="page-link">
                                <i className="fas fa-arrow-left"></i>
                            </span>
                        </li>
                        {this.state.Li.map((li, i) => {
                            if (li.Active) {
                                return (
                                    <li className="page-item active-paggination" aria-current="page" key={i} onClick={this.props.click}>
                                        <span className="page-link active-paggination">{li.Numb}</span>
                                    </li>
                                );
                            } else {
                                return (
                                    <li className="page-item" key={i} onClick={this.props.click}>
                                        <a className="page-link" href="#">
                                            {li.Numb}
                                        </a>
                                    </li>
                                );
                            }
                        })}
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={this.props.click}>
                                <i className="fas fa-arrow-right"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            );
        } else {
            return (<div></div>)
        }
    }
}

export default Paggination;
