import React, { Fragment } from 'react';
import { submitForm, api, tanggalIndo } from '../Modul';
import Pagination from '../component/Pagination';
import RendTable from '../component/RendTable';
import ImageEditor from '../component/ImageEditor';

class MenuKomentarArtikel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Detail: {},
            JmlData: 0,
            ID: 0,
            Order: "Nama",
            By: "ASC",
            Page: 1,
            q: "",
            saveCls: "fa fa-save",
            thead: [],
            tbody: [],
            dataArtikel: [],
            dataKategori: [],
            ArtikelID: "",
            KategoriID: "",
            Judul: "",
            Status: 0,
            Komentar: ""
        };
    }

    async componentDidMount() {
        let sql = await api("/artikel", { act: "data kategori", sort: "Nama", by: "ASC", page: "1", q: "" });
        if (sql.status == "sukses") {
            this.setState({ dataKategori: sql.data });
            this.handleMain();
            let ColorTheme = localStorage.getItem("ColorTheme") || "light";
            if (ColorTheme == "dark") {
                let cls = document.getElementsByClassName("table");
                for (let i = 0; i < cls.length; i++) {
                    cls[i].classList.add("table-dark")
                }
            }
        }
    }

    async handleMain() {
        let sql = await api("/artikel", { act: "data komentar", order: this.state.Order, by: this.state.By, page: this.state.Page, q: this.state.q, ArtikelID: this.state.ArtikelID });
        if (sql.status == "sukses") {
            let thead = [
                { text: "", field: "", sort: "", type: "opsi", width: "200px" },
                { text: "Artikel", field: "Judul", sort: "Judul" },
                { text: "Nama", field: "Nama", sort: "Nama" },
                { text: "Email", field: "Email", sort: "Email" },
                { text: "Komentar", field: "Komentar", sort: "Komentar" },
                { text: "Tanggal Buat", field: "TimeCreated", sort: "TimeCreated", type: "date" },
                { text: "Status", field: "Status", sort: "Status", type: "boolean" }
            ];
            this.setState({ tbody: sql.data, thead: thead, JmlData: sql.j });
        }
    }

    handleSort(Field) {
        if (this.state.By == "DESC") {
            this.setState({ Order: Field, By: "ASC" });
        } else {
            this.setState({ Order: Field, By: "DESC" });
        }
        this.handleMain();
    }

    handlePaggination(e) {
        if (e.target.className == "page-link") {
            let Page = e.target.innerText;
            this.setState({ Page: Page });
            setTimeout(() => {
                this.handleMain();
            }, 200);
        }
    }

    handleDetail(Detail) {
        this.setState({ Detail: Detail, ID: Detail.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalBalas'), {});
        modal.show("#modalBalas");
    }

    handleDelete(data) {
        this.setState({ ID: data.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalDelete'), {});
        modal.show("#modalDelete");
    }

    async handleModalArtikel(e) {
        let sql = await api("/artikel", { act: "data artikel", order: "Judul", by: "ASC", page: "1", q: this.state.Judul, KategoriID: this.state.KategoriID });
        if (sql.status == "sukses") {
            this.setState({ dataArtikel: sql.data });
            let modal = new window.bootstrap.Modal(document.getElementById('modalArtikel'), {});
            modal.show("#modalArtikel");
        }
    }

    async handleCariArtikel() {
        let sql = await api("/artikel", { act: "data artikel", order: "Judul", by: "ASC", page: "1", q: this.state.Judul, KategoriID: this.state.KategoriID });
        if (sql.status == "sukses") this.setState({ dataArtikel: sql.data });
    }

    handlePilihArtikel(data) {
        this.setState({ Judul: data.Judul, ArtikelID: data.ID });
        setTimeout(() => {
            this.handleMain();
            document.getElementById('btnTutupModalCariArtikel').click();
        }, 200);
    }

    handleApproval(e, Status) {
        this.setState({ Status: Status, ID: e.ID });
        let modal = new window.bootstrap.Modal(document.getElementById('modalApproval'), {});
        modal.show("#modalApproval");
    }

    render() {
        return (
            <Fragment>
                <div className="main-header"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-6 main-title">Komentar</div>
                            <div className="col-md-6 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.Judul} placeholder='Filter Artikel' onClick={(e) => this.handleModalArtikel(e)} />
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTable thead={this.state.thead} tbody={this.state.tbody} opt={{
                            sort: (e) => this.handleSort(e),
                            opsi: [
                                { icon: "fas fa-check-circle", text: "Setujui", opsi: (e) => this.handleApproval(e, '1') },
                                { icon: "fas fa-times-circle", text: "Tolak", opsi: (e) => this.handleApproval(e, '0') },
                                { icon: "fas fa-comment-medical", text: "Balas", opsi: (e) => this.handleDetail(e) },
                                { icon: "fas fa-trash", text: "Hapus", opsi: (e) => this.handleDelete(e) }
                            ]
                        }} />
                        <Pagination Jml={this.state.JmlData} Page={this.state.Page} click={(e) => this.handlePaggination(e)} />
                    </div>
                </div>

                {/* Modal Artikel */}
                <div className="modal fade" id="modalArtikel" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <input type='hidden' name="act" value="hapus artikel" />
                        <input type='hidden' name="ID" value={this.state.ID} />
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Artikel</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <select className='form-select' value={this.state.KategoriID} onChange={(e) => {
                                            this.setState({ KategoriID: e.target.value });
                                            setTimeout(() => {
                                                this.handleCariArtikel();
                                            }, 300);
                                        }} >
                                            <option value="">Semua Kategori</option>
                                            {
                                                this.state.dataKategori.map((opt, ii) => {
                                                    return (<option value={opt.ID} key={opt.ID} >{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-6'>
                                        <input className='form-control' value={this.state.Judul} onChange={(e) => {
                                            this.setState({ Judul: e.target.value });
                                            setTimeout(() => {
                                                this.handleCariArtikel();
                                            }, 300);
                                        }} placeholder='Cari Judul Artikel' />
                                    </div>
                                </div>
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Judul</th>
                                                <th>Kategori</th>
                                                <th>Pembuat</th>
                                                <th>Tanggal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.dataArtikel.map((td, ii) => {
                                                    return (<tr key={ii} onClick={() => this.handlePilihArtikel(td)}>
                                                        <td>{td.Judul}</td>
                                                        <td>{td.Kategori}</td>
                                                        <td>{td.Pembuat}</td>
                                                        <td>{tanggalIndo(td.Tanggal)}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariArtikel'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Balas Komentar */}
                <div className="modal fade" id="modalBalas" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={(e) => submitForm(e, { crud: "artikel", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="balas komentar" />
                            <input type='hidden' name="ReplayID" value={this.state.Detail.ID} />
                            <input type='hidden' name="ArtikelID" value={this.state.Detail.ArtikelID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Balas Komentar</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Komentar</label>
                                        <textarea className='form-control' name='Komentar' value={this.state.Komentar} onChange={(e) => this.setState({ Komentar: e.target.value })} placeholder='Tulis komentar anda' required />
                                        <div className='invalid-feedback'>Silahkan isi komentar</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapus'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Balas</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Modal Approval */}
                <div className="modal fade" id="modalApproval" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={(e) => submitForm(e, { crud: "artikel", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="proses komentar" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <input type='hidden' name="Status" value={this.state.Status} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Proses Komentar</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan memproses data ini!!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapus'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className={this.state.Status == 0 ? "fas fa-times-circle" : "fas fa-check-circle"}></i> {this.state.Status == 1 ? "Setujui" : "Tolak"}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Modal Delete */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={(e) => submitForm(e, { crud: "artikel", fn: () => this.handleMain() })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value="hapus komentar" />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Komentar</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus komentar ini!!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapus'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default MenuKomentarArtikel;
